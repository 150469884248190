import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { isMobileDevice } from "./utils";
import "./App.css";
import "./QRRedirect.css";

/**
 * QRRedirect component that shows a loading spinner and redirects to Google
 * @returns {JSX.Element} QRRedirect component
 */
const QRRedirect = () => {
  const [countdown, setCountdown] = useState(4);
  const [redirecting, setRedirecting] = useState(true);

  useEffect(() => {
    if (!redirecting) return;

    // Set up countdown timer
    const timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    // Set up redirection after 2 seconds
    const redirectTimer = setTimeout(() => {
      window.location.href = 'https://www.bitpay.co.il/app/me/7FC1B470-28C4-46BB-0ED0-EBFFE5F47A12AB7F';
    }, 3000);

    // Clean up timers on unmount
    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [redirecting]);

  return (
    <div className="app-container">
      <div className="main-content qr-redirect-content">
        <h2 className="redirect-typewriter">
          <h2>
              מתן יכין סרט חתונה לדין. 
          </h2>
        </h2>
        {/* {<div className="countdown">{countdown}</div>} */}
        {(
          <>
            <CircularProgress 
              size={60} 
              thickness={4} 
              className="redirect-spinner"
              sx={{ 
                color: '#ed964b', 
                marginTop: '20px' 
              }} 
            />
          </>
        )}
      </div>
    </div>
  );
};

export default QRRedirect; 
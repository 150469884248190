import { FaGithub, FaLinkedin, FaTwitter, FaCoffee } from "react-icons/fa";
import "./App.css";

/**
 * Social media links configuration
 */
const SOCIAL_LINKS = [
    {
        href: "https://www.linkedin.com/in/yoav-tepper-30283b131",
        icon: FaLinkedin,
        ariaLabel: "LinkedIn Profile"
    },
    {
        href: "https://github.com/SgtTepper",
        icon: FaGithub,
        ariaLabel: "GitHub Profile"
    },
    {
        href: "https://twitter.com/TepperYoav",
        icon: FaTwitter,
        ariaLabel: "Twitter Profile"
    },
    {
        href: "https://ko-fi.com/yoavtepper",
        icon: FaCoffee,
        ariaLabel: "Ko-fi Support Page"
    }
];

/**
 * SocialButtons component displays social media links
 * @returns {JSX.Element} Social buttons component
 */
export default function SocialButtons() {
    return (
        <div className="social-icons">
            {SOCIAL_LINKS.map((link, index) => (
                <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="icon-link"
                    aria-label={link.ariaLabel}
                >
                    <link.icon color="white" size={40} className="social-icon" />
                </a>
            ))}
        </div>
    );
}

import { useState, useRef, useEffect } from "react";
import { Typewriter } from "react-simple-typewriter";
import Typography from "@mui/material/Typography";

/**
 * Array of skills with their image URLs and labels
 */
const SKILLS = [
    {
        imageUrl: "/python.svg",
        label: "Python",
    },
    {
        imageUrl: "/postgresql.svg",
        label: "SQL",
    },
    {
        imageUrl: "/react.svg",
        label: "React",
    },
    {
        imageUrl: "/tableau.svg",
        label: "Tableau",
    },
    {
        imageUrl: "/metabase.svg",
        label: "Metabase",
    },
    {
        imageUrl: "/snowflake.svg",
        label: "Snowflake",
    },
    {
        imageUrl: "/dotnet.svg",
        label: "C#",
    },
    {
        imageUrl: "/firebase.svg",
        label: "Firebase",
    },
    {
        imageUrl: "/googleanalytics.svg",
        label: "GA",
    },
    {
        imageUrl: "/openai.svg",
        label: "OpenAI",
    },
    {
        imageUrl: "/googlecloud.svg",
        label: "GCP",
    },
    {
        imageUrl: "/microsoftazure.svg",
        label: "Azure",
    },
];

// Constants for animation
const TYPE_SPEED = 40;
const INTERSECTION_THRESHOLD = 0.1;
const TYPEWRITER_TEXT = `"These are a few of my favorite things"`;

/**
 * Stack component displays the tech stack with a typewriter animation
 * @returns {JSX.Element} Stack component
 */
export default function Stack() {
    const [startTyping, setStartTyping] = useState(false);
    const [startSecondTyping, setStartSecondTyping] = useState(false);
    const stackSectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Start the first animation
                        setStartTyping(true);

                        // Calculate the duration of the first animation
                        const text = TYPEWRITER_TEXT;
                        const duration = text.length * TYPE_SPEED + 900;

                        // Start the second animation after the first one finishes
                        setTimeout(() => {
                            setStartSecondTyping(true);
                        }, duration);
                    } else {
                        // When the section is no longer in view, reset the animations
                        setStartTyping(false);
                        setStartSecondTyping(false);
                    }
                });
            },
            { threshold: INTERSECTION_THRESHOLD }
        );

        if (stackSectionRef.current) {
            observer.observe(stackSectionRef.current);
        }

        return () => {
            if (stackSectionRef.current) {
                observer.unobserve(stackSectionRef.current);
            }
        };
    }, []);

    return (
        <div ref={stackSectionRef}>
            <div>
                <Typography
                    style={{
                        textAlign: "center",
                        padding: "20px 5px",
                        fontFamily: "Courier",
                        fontSize: "1.5em",
                        color: "#ed964b",
                    }}
                >
                    {startTyping && (
                        <Typewriter
                            words={[TYPEWRITER_TEXT]}
                            typeSpeed={TYPE_SPEED}
                        />
                    )}
                </Typography>
            </div>
            <div className="stack-skills">
                {SKILLS.map((skill) => (
                    <div className="skill-item" key={skill.label}>
                        <img
                            className="skill-icon"
                            src={skill.imageUrl}
                            alt={skill.label}
                        />
                        <div className="skill-label">{skill.label}</div>
                    </div>
                ))}
            </div>
            {/* <div>
                <Typography
                    style={{
                        textAlign: "center",
                        padding: "40px 5px",
                        fontFamily: "Courier",
                        fontSize: "1.5em",
                        color: "#ed964b",
                    }}
                >
                    {startSecondTyping && (
                        <Typewriter
                            words={["But no worries! I'm a fast learner."]}
                            typeSpeed={40}
                        />
                    )}
                </Typography>
            </div> */}
        </div>
    );
}
